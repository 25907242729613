import React from "react";

// Images
import logo from "../images/ic_logo.svg";

import styled from "styled-components";
import { breakpoints } from "../utils/size";

export const TermsWrapper = styled.div`
  background: ${(props) => props.theme.black};
  padding: 40px 0px;

  @media only screen and (${breakpoints.mobile}) {
    padding: 40px 20px;
  }

  img {
    display: flex;
    margin: auto;
  }
`;

export const TermContent = styled.div`
  width: 877px;
  margin: 40px auto;

  @media only screen and (${breakpoints.mobile}) {
    width: 100%;
  }

  h1 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 36px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 50px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 32px;
      line-height: 46px;
    }
  }

  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    margin: 40px 0 4px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  h3 {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 20px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 28px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 16px;
      line-height: 24px;
    }

    a {
      font-family: "SatoshiRegular";
      color: ${(props) => props.theme.green};
    }
  }
`;


const Terms = () => {
    return (
        <TermsWrapper>
            <a href="/">
                <img src={logo} alt={logo} />
            </a>
            <TermContent>
                <h1>Terms and Conditions</h1>
                <h3>
                    Founded in 2022 by experts with collective 30+ years of experience in
                    sports performance analysis and scouting industry. We see our mission
                    as servicing the professional sports' organisations with the highest
                    level data driven tools. We have already launched our online video
                    platform for Performance Analysis & Scouting!
                </h3>

                <h2>Some label</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    sit amet tempus neque. Sed porttitor dui eget neque egestas, a
                    ullamcorper lorem ultricies. Mauris vitae mollis velit, id scelerisque
                    eros. In interdum eget ligula non pulvinar. Nullam mollis feugiat
                    tortor. Donec in mauris in turpis interdum consectetur gravida ac
                    neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus magna,{" "}
                    <a href="/">ornare</a> non ullamcorper tristique, aliquet{" "}
                    <a href="/">ornare</a> risus. Sed consectetur mauris luctus neque
                    aliquet, et rutrum dui mollis. Duis faucibus facilisis massa, sed
                    finibus justo condimentum eget. In efficitur purus eu ultricies
                    bibendum. In hac habitasse platea dictumst. Donec lorem sem, pretium
                    et lectus in, facilisis elementum mi. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse sit amet tempus neque. Sed
                    porttitor dui eget neque egestas, a ullamcorper lorem ultricies.
                    Mauris vitae mollis velit, id scelerisque eros. In interdum eget
                    ligula non pulvinar. Nullam mollis feugiat tortor. Donec in mauris in
                    turpis interdum consectetur gravida ac neque. Mauris males
                </p>

                <h2>Some label</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    sit amet tempus neque. Sed porttitor dui eget neque egestas, a
                    ullamcorper lorem ultricies. Mauris vitae mollis velit, id scelerisque
                    eros. In interdum eget ligula non pulvinar. Nullam mollis feugiat
                    tortor. Donec in mauris in turpis interdum consectetur gravida ac
                    neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus magna,
                    ornare non ullamcorper tristique, aliquet ornare risus. Sed
                    consectetur mauris luctus neque aliquet, et rutrum dui mollis. Duis
                    faucibus facilisis massa, sed finibus justo condimentum eget. In
                    efficitur purus eu ultricies bibendum. In hac habitasse platea
                    dictumst. Donec lorem sem, pretium et lectus in, facilisis elementum
                    mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
                    egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
                    scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
                    feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
                    ac neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
                    egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
                    scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
                    feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
                    ac neque. Mauris malesuada placerat enim ut accumsan. Nunc lacus
                    magna, ornare non ullamcorper tristique, aliquet ornare risus. Sed
                    consectetur mauris luctus neque aliquet, et rutrum dui mollis. Duis
                    faucibus facilisis massa, sed finibus justo condimentum eget. In
                    efficitur purus eu ultricies bibendum. In hac habitasse platea
                    dictumst. Donec lorem sem, pretium et lectus in, facilisis elementum
                    mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse sit amet tempus neque. Sed porttitor dui eget neque
                    egestas, a ullamcorper lorem ultricies. Mauris vitae mollis velit, id
                    scelerisque eros. In interdum eget ligula non pulvinar. Nullam mollis
                    feugiat tortor. Donec in mauris in turpis interdum consectetur gravida
                    ac neque. Mauris males
                </p>
            </TermContent>
        </TermsWrapper>
    );
};

export default Terms;
