import styled, { createGlobalStyle } from "styled-components";
import SpaceGrotesk from "../fonts/WEB/fonts/SpaceGrotesk.ttf";
import SatoshiRegular from "../fonts/WEB/fonts/Satoshi-Regular.otf";
import SatoshiItalic from "../fonts/WEB/fonts/Satoshi-Italic.otf";
import SatoshiMedium from "../fonts/WEB/fonts/Satoshi-Medium.otf";
import SatoshiBold from "../fonts/WEB/fonts/Satoshi-Bold.otf";
import background from "../images/background.svg";

export const lightTheme = {
  background: "#fff",
  black: "#FFFFFF",
  blueish: "#4E5255",
  blueish2: "#A4ADB6",
  blueish3: "#E6E6E6",
  blueish4: "#2363F7",
  lines: "#2363f744",
  linesOpacity: "rgba(233, 233, 233, 0.4)",
  // row: "#F0F0F0",
  row: "#2363f744",
  white: "#040802",
  green: "#2363F7",
  green2: "#DCE4F6",
  greengreen: "#31CA29",
  red: "#F20A46",
  yellow: "#F5A028",
  sidebar: "#eeeeeea3",
  sidebarNew: "#eeeeee4d",
  bgOverlay: "#fbfbfb",
  borderModal: "#dce4f6",
  eventsPanel: "#f6f6f6",
  borderColor: "#918f8f63",
};

export const darkTheme = {
  background: `url(${background})`,
  black: "#040802",
  blueish: "#CFDAE6",
  blueish2: "#738089",
  blueish3: "#2C3230",
  blueish4: "#CFDAE6",
  lines: "#181918",
  linesOpacity: "#18191847",
  row: "#1D211B",
  white: "#FFFFFF",
  green: "#BFE82D",
  green2: "#181F04",
  greengreen: "#36E82D",
  red: "#F20A46",
  yellow: "#E8D02D",
  sidebar: "#18191845",
  sidebarNew: "#18191845",
  bgOverlay: "#040802",
  borderModal: "#181F04",
  eventsPanel: "#040802",
  borderColor: "#181918",
};


export const getColor = (color, theme = localStorage.getItem("theme")) => {
  return theme === "light" ? lightTheme[color] : darkTheme[color];
};

export const GlobalStyles = createGlobalStyle`
  /**
    * @license
    *
    * Font Family: Satoshi
    * Designed by: Deni Anggara
    * URL: https://www.fontshare.com/fonts/satoshi
    * © 2023 Indian Type Foundry
    *
    * Font Styles:
    * Satoshi Variable(Variable font)
    * Satoshi Variable Italic(Variable font)
    * Satoshi Light
    * Satoshi Light Italic
    * Satoshi Regular
    * Satoshi Italic
    * Satoshi Medium
    * Satoshi Medium Italic
    * Satoshi Bold
    * Satoshi Bold Italic
    * Satoshi Black
    * Satoshi Black Italic
    *
    */


  /**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 900.0;
  *
  * available axes:
  
  * 'wght' (range from 300.0 to 900.0)
  
  */

 
    @font-face {
      font-family: 'SpaceGrotesk';
      src:  url('${SpaceGrotesk}') format('truetype');

    }

    @font-face {
    font-family: 'SatoshiItalic';
    src: url('${SatoshiItalic}') format('opentype');

  }


   @font-face {
    font-family: 'SatoshiRegular';
    src: url('${SatoshiRegular}') format('opentype');

  }

   @font-face {
    font-family: 'SatoshiMedium';
    src: url('${SatoshiMedium}') format('opentype');

  }

   @font-face {
    font-family: 'SatoshiBold';
    src: url('${SatoshiBold}') format('opentype');

  }

  //BASE CSS
  * {
    margin: 0;
    padding: 0;
    font-family: 'Satoshi-Regular';
    color: ${(props) => props.theme.green};
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.blueish2};
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.green};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.green};
  }
  
  //TYPOGRAPHY
  h1,h2,h3,h4,h5,h6,p,a,span{
    margin:0; 
    padding:0;
  }
  h1,h2,h3,h4,h5,h5{
  }
  h1, .h1 {
    font-size:42px;
    line-height: 54px;
    font-weight: 700;
    margin: 0;
  }
  h2, .h2 {
    font-size:36px;
    line-height: 48px;
    font-weight: 500;
  }
  h3, .h3 {
    font-size:40px;
    line-height: 52px;
    font-weight: 400;
  }
  h4, .h4 {
    font-size:28px;
    line-height: 38px;
    font-weight: 500;
  }
  h5, .h5 {
    font-size:18px;
    line-height: 26px;
    font-weight: 500;
  }
  h6, .h6 {
    font-size:16px;
    line-height: 24px;
    font-weight: 500;
  }
  
  
  p,body,a,div {
    font-size: 16px;
    line-height: 24px;
    //font-weight: 400;
  }
  
  
  .container {
    width: 100%;
    max-width: 640px;
    flex-shrink: 0!important;
  }
  
  
 
  //OTHER
  .shadow {
    box-shadow: 0px 1px 15px rgba(2,11,32, 0.05);
  }

  .zoom {
    transition: transform .2s; /* Animation */
  }

  .zoom:hover {
    transform: scale(1.02); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .flex-start {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .flex-end {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .flex-between,.flex-between-center {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-column {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .cursor-pointer {
    cursor: pointer;
  }
 
  #formation {
    svg, g {
      transition: all 0.5s ease-in-out;
    }

    .tactic:hover {
      cursor: pointer;
      fill: #c1b033 !important;
    }
  }

  .draggble {
    stroke: blue;
  }

  .loading-cursor {
    cursor: wait;
  }
 
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

export const SelectDropdown = styled.div`
  select {
    background: transparent;
    border: none;
    border-radius: 1px;
    border: 1px solid rgba(44, 50, 48, 1);
    opacity: 1;
    height: 40px;
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
    padding: 5px 10px;
    outline: none;
  }
`;

export const EmptyCard = styled.div`
  h2 {
    opacity: 1;
    color: ${(props) => props.theme.white};
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 30px;
  }
`;
