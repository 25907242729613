import React from "react";

// Style
import {
    FooterWrapper,
    FooterLeftSide,
    FooterRightSide,
    Content,
} from "../styles/LandingPageStyles";

// Images
import logo from "../../../images/footerLogo.svg";

// Language
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <FooterWrapper>
            <FooterLeftSide>
                <img src={logo} alt={logo} />
                <h4>{t("footer.desc")}</h4>
            </FooterLeftSide>
            <FooterRightSide>
                <Content>
                    <h5>{t("footer.mainLink")}</h5>
                    <ul>
                        <li>
                            <a href="/#platform">{t("footer.platform")}</a>
                        </li>
                        <li>
                            <a href="/#services">{t("footer.services")}</a>
                        </li>
                        <li>
                            <a href="/#about">{t("footer.aboutUs")}</a>
                        </li>
                        <li>
                            <a href="/#contact">{t("footer.contactUs")}</a>
                        </li>
                    </ul>
                </Content>
                <Content>
                    <h5>{t("footer.otherLinks")}</h5>
                    <ul>
                        <li>
                            <a href="/privacy">{t("footer.privacyPolicy")}</a>
                        </li>
                        <li>
                            <a href="/terms">{t("footer.termsConditions")}</a>
                        </li>
                    </ul>
                </Content>
                <Content>
                    <h5>{t("footer.contactDetails")}</h5>
                    <ul>
                        <li>
                            <a href="mailto:sales@smrtstats.com">sales@smrtstats.com</a>
                        </li>
                        <li>
                            <a>
                                13 Honeypark, Dun Laoghaire, <br />Dublin, Ireland
                            </a>
                        </li>
                    </ul>
                </Content>
            </FooterRightSide>
        </FooterWrapper>
    );
};

export default Footer;
