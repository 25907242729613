import styled from "styled-components";
import { breakpoints } from "../../../utils/size";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 20px;
`;

export const Languages = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;

  h4 {
    opacity: 1;
    font-family: "SpaceGrotesk";
    font-size: 12px;
    font-weight: 700;
    font-style: Bold;
    letter-spacing: 0px;
    text-align: center;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;

    @media only screen and (${breakpoints.mobile}) {
      font-size: 16px;
    }

    &.active {
      color: ${(props) => props.theme.white};
    }

    &.inactive {
      color: ${(props) => props.theme.blueish2};
    }
  }

  span {
    color: ${(props) => props.theme.blueish2};
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 40px;
`;

export const Content = styled.div`
  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    gap: 30px;

    li {
      a {
        opacity: 1;
        color: ${(props) => props.theme.green};
        font-family: "SpaceGrotesk";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 24px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
`;

export const WrapperMobile = styled.div`
  position: absolute;
  width: 100%;
`;

export const MobileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid rgba(24, 25, 24, 1);
  box-shadow: 0px 2px 30px rgba(68, 68, 68, 0.12);
  opacity: 1;
  background-color: ${(props) => props.theme.sidebar};
`;

export const MobileRightElements = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  background: rgb(4 8 2 / 65%);
  backdrop-filter: blur(5px);
`;

export const OpenMobileMenu = styled.div`
  background: ${(props) => props.theme.black};
  padding: 20px 20px 40px 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style-type: none;

    li {
      a {
        opacity: 1;
        color: ${(props) => props.theme.green};
        font-family: "SpaceGrotesk";
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 24px;
        text-transform: uppercase;
        text-decoration: none;
      }

      &:last-child {
        padding-bottom: 40px;
      }
    }
  }
`;
