import React from "react";

// Style
import styled, { css } from "styled-components";
import { darkTheme } from "../../../styles/Global";

const colorOptions = {
  primary: (props) => props.theme.green,
  normal: (props) => props.theme.white,
  gray: (props) => props.theme.blueish2,
};

const sizeOptions = {
  small: {
    fontSize: "12px",
  },
  medium: {
    fontSize: "16px",
  },
  large: {
    fontSize: "20px",
  },
};

export const ButtonLinkStyle = styled.a`
  background: transparent;
  color: ${(props) => colorOptions[props.color]};
  font-size: ${(props) => sizeOptions[props.size].fontSize};
  opacity: 1;
  font-family: "SatoshiBold";
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: right;
  line-height: 22px;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${(props) =>
      colorOptions[props.hoverColor] ||
      colorOptions[props.color] ||
      darkTheme.darkGray};
    text-decoration: underline;
  }

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;

      // &:hover {
      //   text-decoration: underline;
      // }
    `};
`;

export const ButtonLinkWrapper = styled.div`
  display: flex;
  flex-directio: row;
  align-items: baselline;
  gap: 8px;
`;

export const ImageLeft = styled.img`
  margin-right: 8px;
`;

const ButtonLink = ({
  size = "medium",
  title = "forgot PASSWORd",
  underline = false,
  color,
  hoverColor,
  href,
  image,
  ...rest
}) => {
  return (
    <ButtonLinkWrapper>
      <ButtonLinkStyle
        {...rest}
        size={size}
        underline={underline}
        href={href}
        color={color}
        hoverColor={hoverColor}
      >
        {title}
      </ButtonLinkStyle>
    </ButtonLinkWrapper>
  );
};

export default ButtonLink;
