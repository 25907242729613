import React from 'react'
import { Landing } from './pages/Landing'
import { Route, Routes } from 'react-router-dom';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />}></Route>
            {/* Privacy pages */}
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
        </Routes>
    )
}
export default App;
