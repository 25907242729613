import React from "react";

// Style
import styled from "styled-components";

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;

  label {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    padding-left: 5px;
  }

  textarea {
    background: transparent;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.blueish2};
    outline: none;
    opacity: 1;
    color: ${(props) => props.theme.blueish};
    font-family: "SatoshiRegular";
    font-size: 16px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    padding-left: 5px;
    padding-right: 40px;

    &:placeholder {
      opacity: 1;
      color: ${(props) => props.theme.blueish2};
      font-family: "SatoshiRegular";
      font-size: 16px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: left;
      line-height: 24px;
    }
  }

  span {
    opacity: 1;
    color: ${(props) => props.theme.blueish2};
    font-family: "SatoshiRegular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: right;
    line-height: 18px;
    position: absolute;
    right: 0;
    bottom: 7px;
  }
}
`;

const TextArea = ({ label, placeholder, value, counter, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextAreaWrapper>
      <label>{label}</label>
      <textarea
        maxLength={800}
        rows="4"
        cols="50"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      <span>{counter}/800</span>
    </TextAreaWrapper>
  );
};

export default TextArea;
