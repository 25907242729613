import About from "./About/About";
import Baner from "./Banner/Banner";
import Contact from "./Contact/Contact";
import Copyright from "./Copyright/Copyright";
import Footer from "./Footer/Footer";
import LandingNavigation from "./Navigation/LandingNavigation";
import Services from "./Services/Services";

export {
    LandingNavigation,
    Baner,
    Services,
    About,
    Contact,
    Footer,
    Copyright
}