import React from "react";

// Style
import {
    ServicesWrapper,
    ServiceCtaAction,
    ServiceList,
    ServiceContent,
    ServiceInfo,
} from "../styles/LandingPageStyles";

// Images
import service1 from "../../../images/service1.svg";
import service2 from "../../../images/service2.svg";
import service3 from "../../../images/service3.svg";
import service4 from "../../../images/service4.svg";
import service5 from "../../../images/service5.svg";
import service6 from "../../../images/service6.svg";

// Language
import { useTranslation } from "react-i18next";

const Services = () => {
    const { t } = useTranslation();

    const serviceList = [
        {
            id: 1,
            img: service1,
            title: "Video Platform",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
        {
            id: 2,
            img: service2,
            title: "Statistical Report",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
        {
            id: 3,
            img: service3,
            title: "Analytical Report",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
        {
            id: 4,
            img: service4,
            title: "Player Transfer Execution",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
        {
            id: 5,
            img: service5,
            title: "Live Data",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
        {
            id: 6,
            img: service6,
            title: "Media Services",
            desc: "Unlock the power of football insights with our Statistical Report feature. Dive deep into the numbers, uncover trends, and gain a strategic edge in the world of football. Elevate your game with data-driven decision-making.",
        },
    ];

    return (
        <>
            <ServicesWrapper id="services">
                <h2>{t("services.title")}</h2>
                <ServiceCtaAction>
                    <p>{t("services.desc")}</p>
                </ServiceCtaAction>

                <ServiceList>
                    {serviceList.map((item, index) => {
                        return (
                            <ServiceContent key={index}>
                                <img src={item.img} alt={item.img} />
                                <ServiceInfo>
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </ServiceInfo>
                            </ServiceContent>
                        );
                    })}
                </ServiceList>
            </ServicesWrapper>
        </>
    );
};

export default Services;
