import React from "react";

// Style
import { WrapperBanner, VideoClip } from "../styles/LandingPageStyles";

// Images
import BanerImage from "../../../images/banerImage.svg";

// Language
import { useTranslation } from "react-i18next";

const Baner = () => {
    const { t } = useTranslation();

    return (
        <WrapperBanner>
            <h1>{t("banner.title")}</h1>
            <p>{t("banner.desc")}</p>
            <img src={BanerImage} alt={BanerImage} className="banerImage" />

            <h1 id="platform">{t("platform.title")}</h1>
            <p>{t("platform.desc")}</p>

            <VideoClip>
                <iframe
                    height="500" 
                    src="https://www.youtube.com/embed/DC4hSKJEifI?si=C4_HnLOrz2NxzKMq"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                />
            </VideoClip>
        </WrapperBanner>
    );
};

export default Baner;
