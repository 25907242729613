import React from "react";

// Style
import { AboutWrapper, TopWrap, AboutImages } from "../styles/LandingPageStyles";

// Images
import about1 from "../../../images/about1.png";
import about2 from "../../../images/about2.png";
import about3 from "../../../images/about3.png";
import about4 from "../../../images/about4.png";

// Language
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <AboutWrapper id="about">
      <TopWrap>
        <h2>{t("aboutUs.title")}</h2>
        <p>{t("aboutUs.desc")}</p>
      </TopWrap>

      <AboutImages>
        <img src={about1} alt={about1} />
        <img src={about2} alt={about2} />
        <img src={about3} alt={about3} />
        <img src={about4} alt={about4} />
      </AboutImages>
    </AboutWrapper>
  );
};

export default About;
