import React from 'react';
import {
    About,
    Baner,
    Contact,
    Copyright,
    Footer,
    LandingNavigation,
    Services,
} from '../components/landing';

export const Landing = () => {
    return (
        <div>
            <LandingNavigation />
            <Baner />
            <Services />
            <About />
            <Contact />
            <Footer />
            <Copyright />
        </div>
    )
}
